<template>
  <div id="app">
    <div class="header flex space_between align_center">
      <img src="../assets/images/top.png" alt="" />
      <van-button icon="plus" color="#9b2033" plain size="small" @click="onClear"
        style="border-radius: 8px">新对话</van-button>
    </div>
    <div class="page" style="padding-top: 50px">
      <section class="chatlist" ref="chatlist">
        <ul style="padding: 0">
          <div v-for="(item, index) in state.records" :key="index">
            <li class="chat-mine" v-if="item.type == 1">
              <div class="chat-msg">
                <div class="chat-text" v-html="item.content"></div>
              </div>
            </li>
            <li v-if="item.type == 2" class="chat_ai">
              <div class="chat-ai">
                <img src="../assets/images/AI.png" alt="" />
                <br />
                <div class="chat-text">
                  <v-md-preview :text="item.content"></v-md-preview>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </section>
      <div style="height: 60px"></div>
      <section class="foot">
        <div class="chat-input">
          <input type="text" v-model="state.content" @keyup.enter="sendMsg" placeholder="请输入你的问题" />
          <img src="../assets/images/send.png" class="custom-icon" @click="sendMsg" />
        </div>
      </section>
    </div>
  </div>
</template>
<script setup>
  import { reactive, ref, toRefs, onMounted, watch } from "vue";
  import { ElMessage, ElMessageBox } from "element-plus";
  import { useRouter } from "vue-router";
  import util from "../assets/util";
  import axios from "axios";
  import VMdPreview from "@kangc/v-md-editor/lib/preview";
  import "@kangc/v-md-editor/lib/style/preview.css";
  import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
  import "@kangc/v-md-editor/lib/theme/style/github.css";
  import hljs from "highlight.js";

  const { v4: uuidv4 } = require("uuid");
  VMdPreview.use(githubTheme, {
    Hljs: hljs,
  });
  const router = useRouter();
  const state = reactive({
    content: "",
    records: [
      // { type: 1, content: "hello" },
      // { type: 2, content: "你好，我是你的AI辅导员，有问题欢迎随时向我提问哦~" },
      // {
      //   type: 1,
      //   content:
      //     "Hello, I am thinking about studying abroad. Could you give me some advice?",
      // },
      // {
      //   type: 2,
      //   content:
      //     "Hello therel How have you been lately? Are you planning to study abroad?",
      // },
      // { type: 1, content: "hello" },
      // { type: 2, content: "你好，我是你的AI辅导员，有问题欢迎随时向我提问哦~" },
      // {
      //   type: 1,
      //   content:
      //     "Hello, I am thinking about studying abroad. Could you give me some advice?",
      // },
      // {
      //   type: 2,
      //   content:
      //     "Hello therel How have you been lately? Are you planning to study abroad?",
      // },
      // { type: 1, content: "hello" },
      // { type: 2, content: "你好，我是你的AI辅导员，有问题欢迎随时向我提问哦~" },
      // {
      //   type: 1,
      //   content:
      //     "Hello, I am thinking about studying abroad. Could you give me some advice?",
      // },
      // {
      //   type: 2,
      //   content:
      //     "Hello therel How have you been lately? Are you planning to study abroad?",
      // },
      // { type: 1, content: "hello" },
      // { type: 2, content: "你好，我是你的AI辅导员，有问题欢迎随时向我提问哦~" },
      // {
      //   type: 1,
      //   content:
      //     "Hello, I am thinking about studying abroad. Could you give me some advice?",
      // },
      // {
      //   type: 2,
      //   content:
      //     "Hello therel How have you been lately? Are you planning to study abroad?",
      // },
    ],
    isRequesting: false,
  });
  onMounted(() => {
    init();
  });
  const init = () => {
    if (localStorage.getItem("uuid")) {
      state.uuid = localStorage.getItem("uuid");
    } else {
      state.uuid = uuidv4();
      localStorage.setItem("uuid", state.uuid);
    }
    getList();
  };
  const onClear = () => {
    localStorage.removeItem("uuid");
    init();
  };

  const sendMsg = async () => {
    if (state.isRequesting || !state.content) {
      return false;
    }
    let query = state.content;
    state.content = "";
    state.isRequesting = true;
    state.records.push({ type: 1, content: query });
    state.records.push({ type: 2, content: "思考中..." });

    scrollToBottom();
    let response;
    try {
      response = await fetch(process.env.VUE_APP_URL + "aiBot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: query, uuid: state.uuid }),
        responseType: "stream",
      });
    } catch (error) {
      ElMessage.error("请求发送失败", error);
      return;
    }

    if (!response || !response.headers) {
      return false;
    }

    let index = state.records.length - 1;
    if (
      response.headers.get("Content-Type") != "text/event-stream; charset=utf-8"
    ) {
      try {
        let Data = await response.json();
        if (Data.error) {
          ElMessage.error(Data.error);
          state.isRequesting = false;
          return;
        }
        if (Data.message) {
          state.records[index].content = Data.message;
          scrollToBottom();
          state.isRequesting = false;
          storageData(query, state.records[index].content);
          return;
        }
      } catch (parseError) {
        ElMessage.error("解析错误信息时出错", parseError);
        return;
      }
    }

    let reader = response.body.getReader();

    let accumulatedData = ""; // 新增：用于累积数据
    state.records[index].content = "";
    while (true) {
      let { done, value } = await reader.read();
      if (done) {
        console.log("end");
        storageData(query, state.records[index].content);
        state.isRequesting = false;
        break;
      }
      let chunk = new TextDecoder("utf-8").decode(value);
      accumulatedData += chunk; // 累积接收到的数据

      let lines = accumulatedData.split("\n"); // 分割累积的数据为行

      for (let line of lines.slice(0, -1)) {
        // 处理除最后一行之外的所有行
        if (line.startsWith("data: ")) {
          let dataStr = line.split("data: ")[1];
          try {
            console.log(dataStr);
            let data = JSON.parse(dataStr);
            if (
              data.choices &&
              data.choices[0] &&
              data.choices[0].delta &&
              data.choices[0].delta.content
            ) {
              let content = data.choices[0].delta.content.replaceAll(
                /\n/g,
                "<br>"
              );
              state.records[index].content = state.records[index].content +=
                content;
              scrollToBottom();
            }
          } catch (error) {
            if (
              error instanceof SyntaxError &&
              error.message.includes("Unexpected token")
            ) {
              console.log("无效的 JSON 数据: ", dataStr);
            } else {
              console.error("解析数据出错:", error);
            }
          }
        }
      }
      accumulatedData = lines[lines.length - 1]; // 保留最后一行未处理的数据，用于下次累积
    }
  };

  const getList = async () => {
    try {
      const response = await axios.post(process.env.VUE_APP_URL + "list", {
        uuid: state.uuid,
        page: 1,
      });
      let data = response.data.data;

      const processedData = [];
      data = data.reverse();
      data.forEach((item) => {
        processedData.push({ type: 1, content: item.in });
        processedData.push({ type: 2, content: item.out });
      });

      state.records = processedData;
      if (state.records.length == 0) {
        state.records.push({
          type: 2,
          content:
            "你好，我是外新人，很高兴为您服务-作为一个人工智能语言模型，您可以向我咨询关于外院的问题，我会尽力帮您查找答案!",
        });
      }
      scrollToBottom();
    } catch (error) {
      console.error(error);
    }
  };
  const storageData = async (query, answer) => {
    try {
      const response = await axios.post(process.env.VUE_APP_URL + "save", {
        uuid: state.uuid,
        query: query,
        answer: answer,
      });
    } catch (error) {
      console.error(error);
    }
  };

  //滚动条滚动到底部
  const scrollToBottom = () => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 10);
  };
</script>
<style lang="less" scoped>
  #app {
    background: linear-gradient(to bottom, #ffeaed, #fcfdff);
    /* background-attachment: fixed; */
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }

  .header {
    background-color: #fdf4f7;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    max-width: 600px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    img {
      width: 65%;
    }
  }

  .page {
    width: 100%;
    padding: 10px 0;
    margin: 0 auto;
  }

  .chatlist {
    width: 100%;
    overflow: auto;
    /* overflow-y: scroll;
  overflow-x: hidden; */
  }

  .chatlist ul .chat-mine {
    text-align: right;
    padding-left: 0;
  }

  .chatlist ul li {
    margin: 20px;
  }

  .chat-msg {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .chat-mine .chat-text {
    text-align: left;
    padding: 10px 20px;
    color: #fff;
    background: #9b2033;
    box-shadow: 0px 2px 8px 0px rgba(255, 204, 204, 0.5);
    border-radius: 10px 10px 0px 10px;
    max-width: 80% !important;
    margin-left: auto !important;
    margin-top: 10px;
  }

  .chat-ai {
    img {
      height: 22px;
    }
  }

  .chat-ai .chat-text {
    /* line-height: 22px; */
    display: inline-block;
    padding: 10px 20px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(255, 204, 204, 0.5);
    border-radius: 10px 10px 10px 0px;
    color: #333;
    max-width: 90%;

    /* word-break: break-all; */
  }

  .foot {
    width: 100%;
    max-width: 600px;
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    background-color: #fff;
    text-align: center;
  }

  .chat-input {
    input {
      position: relative;
      box-sizing: border-box;
      width: 94%;
      padding: 14px;
      border-radius: 32px;
      border: 1px solid #9b2033;
    }

    .custom-icon {
      position: absolute;
      width: 30px;
      top: 50%;
      transform: translateY(-50%);
      right: 26px;
    }
  }

  /* 隐藏滚动条 */

  /* 自定义滚动条样式 */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /deep/.github-markdown-body {
    padding: 0;

    p {
      margin-bottom: 0;
    }
  }

  .align_center {
    align-items: center;
  }
</style>